import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import AuthSimpleRoutes from 'components/authentication/simple/AuthSimpleRoutes';
import { AidaAppContext } from 'context/AidaAppContext';

const AuthSimpleLayout = () => {
  const { user_role } = useContext(AidaAppContext);

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col
          sm={10}
          md={8}
          lg={6}
          xl={(user_role == 'Sudo Admin' || user_role == "System Admin") ? 10 : 5}
          xxl={(user_role == 'Sudo Admin' || user_role == "System Admin") ? 10 : 4}
        >
          <Logo />
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <AuthSimpleRoutes />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
export default AuthSimpleLayout;
