import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCountrySelectBox from 'components/aida/custom-form-hooks/useCountrySelectBox';
import useLanguageSelectBox from 'components/aida/custom-form-hooks/useLanguageSelectBox';
import useUserSelectBox from 'components/aida/custom-form-hooks/useUserSelectBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import React, { useState } from 'react';
import { Modal, Col, Form, Row, CloseButton } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import { useMutation, useQuery } from 'react-query';
import EditKeywordUrl from './EditKeywordUrl';
import useReportLanguageSelectBox from 'components/aida/custom-form-hooks/useReportLanguageSelectBox';
import { isNull } from 'lodash';
import SendMonthlyReportModal from './SendMonthlyReportModal';
import usePlansSelectBox from 'components/aida/custom-form-hooks/usePlansSelectBox';
import { hasChanged } from 'helpers/utils';
import TerminationForm from './TerminationForm';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const UpsertModal = ({
  projectId = '',
  iconOnly = false,
  isEditing = false,
  refreshListing
}) => {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({
    managing_user_receives_monthly_report: null,
    plan_id: null,
    note: null,
    name: null,
    domain: null,
    brand_name: null,
    company_description: null,
    client_main_email: null,
    is_email_verified: null,
    email_for_reports: null,
    country_id: null,
    language_id: null,
    report_language_id: null,
    managing_user_id: null,
    active: null
  });

  const { handleSubmit, setValue, register, control, reset, watch, getValues } = useForm();

  const mutationActions = {
    onSuccess: json => {
      if (json?.status == 422) {
        setErrors(json.errors);
      } else if (json?.status == 200) {
        postUpdateActions();
      }
    }
  };

  const UpdateSeoProjectMutation = useMutation(
    'update-seo-project',
    formData => CallApi.put('/seo-project/' + projectId, formData, true, false),
    mutationActions
  );

  const onSubmit = (data, form) => {
    if (UpdateSeoProjectMutation.isLoading) {
      alert('Please wait! we are already updating this project');
      return;
    }

    let formData = new FormData(form.target);

    formData.set('managing_user_id', data.managing_user_id);

    UpdateSeoProjectMutation.mutate(formData);
  };

  const { data, isFetching, refetch } = useQuery(
    ['seo-project', projectId],
    () => CallApi.get('/seo-project/' + projectId, false),
    {
      onSuccess: json => {
        if (json?.status == 200) {
          reset(json.data);
          setValue(
            'email_for_reports',
            json?.data?.seo_project_client_emails
              ?.map(emailObj => emailObj.email)
              ?.join(',')
          );
          setValue('active', json?.data?.active ? 1 : 0);
        }
      },
      enabled: projectId && show
    }
  );

  const handleClose = () => {
    setShow(false);
  };

  const postUpdateActions = () => {
    handleClose();
    refreshListing();
  };

  const ManagingUserSelectBox = useUserSelectBox('/users');
  const { CountrySelectbox } = useCountrySelectBox(
    'id',
    '/countries?for=seo_projects'
  );
  const { LanguageSelectBox } = useLanguageSelectBox();
  const { PlanSelectBox } = usePlansSelectBox();
  const { ReportLanguageSelectBox } = useReportLanguageSelectBox();
  return (
    <>
      {iconOnly ? (
        <FontAwesomeIcon
          icon={'edit'}
          className="cursor-pointer text-primary"
          onClick={() => setShow(true)}
        />
      ) : (
        <IconButton
          variant="outline-primary"
          className="rounded-pill"
          icon={isEditing ? 'edit' : 'plus'}
          size="sm"
          onClick={() => setShow(true)}
        >
          {!isEditing && 'Add Project'}
        </IconButton>
      )}
      <Modal show={show} size="xl" backdrop="static" centered>
        <Modal.Header>
          <Modal.Title>
            {isEditing ? 'Edit Project' : 'Add Project'}
          </Modal.Title>
          <CloseButton
            onClick={() => {
              const fieldsToConsiderForUnsavedChanges = [
                'name',
                'note',
                'plan_id',
                'domain',
                'brand_name',
                'client_main_email',
                'is_email_verified',
                'email_for_reports',
                'country_id',
                'language_id',
                'report_language_id',
                'managing_user_id',
                'managing_user_receives_monthly_report',
                'active',
                'competitor_1',
                'competitor_2',
                'competitor_3',
                'competitor_4'
              ];

              // preparing an object to compare with new data
              const oldData = {
                ...data?.data,
                email_for_reports: data?.data?.seo_project_client_emails
                  ?.map(emailObj => emailObj.email)
                  ?.join(',')
              };

              const filteredOldData = Object.keys(oldData)
                .filter(key => fieldsToConsiderForUnsavedChanges.includes(key))
                .reduce((obj, key) => {
                  obj[key] = oldData[key] || null;
                  return obj;
                }, {});

              const newData = getValues();
              const filteredNewData = Object.keys(newData)
                .filter(key => fieldsToConsiderForUnsavedChanges.includes(key))
                .reduce((obj, key) => {
                  obj[key] = newData[key] || null;
                  return obj;
                }, {});

              if (hasChanged(filteredOldData, filteredNewData)) {
                alert('You have unsaved changes, kindly update or cancel.');
              } else {
                setShow(false);
              }
            }}
          />
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form id="seo-project-form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Project Name
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_name'}
                    />
                  </Form.Label>
                  <Form.Control
                    {...register('name', { required: true })}
                    isInvalid={errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && errors.name.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Note
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_note'}
                    />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    {...register('note')}
                    isInvalid={errors.note}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.note && errors.note.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Subscription
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_field_subscription'}
                    />
                  </Form.Label>
                  <Controller
                    control={control}
                    name="plan_id"
                    render={({ field }) => (
                      <PlanSelectBox
                        className={errors?.plan_id && 'is-invalid'}
                        isSearchable
                        onChange={plan => field.onChange(plan.id)}
                        value={field.value}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.plan_id?.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Domain
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_domain'}
                    />
                  </Form.Label>
                  <Form.Control
                    disabled
                    {...register('domain', { required: true })}
                    isInvalid={errors.domain}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.domain && errors.domain.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Company Name to display in text
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_brand_name'}
                    />
                  </Form.Label>
                  <Form.Control
                    {...register('brand_name', { required: false })}
                    isInvalid={errors.brand_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.brand_name && errors.brand_name.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Company Description
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_company_description'}
                    />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    {...register('company_description')}
                    isInvalid={errors.company_description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.company_description &&
                      errors.company_description.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Client Email
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_field_client_main_email'}
                    />
                  </Form.Label>
                  <Form.Control
                    type="email"
                    {...register('client_main_email', { required: true })}
                    isInvalid={errors.client_main_email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.client_main_email?.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email verified ?
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_field_is_email_verified'}
                    />
                  </Form.Label>
                  <Form.Select
                    disabled
                    {...register('is_email_verified')}
                    isInvalid={errors.is_email_verified}
                  >
                    <option value={'true'}>Yes</option>
                    <option value={'false'}>No</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.is_email_verified &&
                      errors.is_email_verified.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    CC emails for reports
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_field_email_for_reports'}
                    />
                  </Form.Label>
                  <Form.Control
                    type="email"
                    multiple
                    {...register('email_for_reports')}
                    isInvalid={errors.email_for_reports}
                  />
                  <Form.Text>
                    Please enter comma seperated emails for more than 1 email
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.email_for_reports &&
                      errors.email_for_reports.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Country
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_region'}
                    />
                  </Form.Label>
                  <Controller
                    control={control}
                    name="country_id"
                    render={({ field }) => (
                      <CountrySelectbox
                        isDisabled
                        className={errors?.country_id && 'is-invalid'}
                        isSearchable
                        onChange={country => field.onChange(country.id)}
                        value={field.value}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.country_id &&
                      errors.country_id.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Text Language
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_language'}
                    />
                  </Form.Label>
                  <Controller
                    control={control}
                    name="language_id"
                    render={({ field }) => (
                      <LanguageSelectBox
                        isDisabled
                        className={errors.language_id && 'is-invalid'}
                        isSearchable
                        name="language_id"
                        onChange={language => field.onChange(language.id)}
                        value={field.value}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.language_id &&
                      errors.language_id.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Report Language
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_field_report_language'}
                    />
                  </Form.Label>
                  <Controller
                    control={control}
                    name="report_language_id"
                    render={({ field }) => (
                      <ReportLanguageSelectBox
                        className={errors.report_language_id && 'is-invalid'}
                        isSearchable
                        onChange={language => field.onChange(language.id)}
                        value={field.value}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.report_language_id &&
                      errors.report_language_id.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    Contact Person
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_managing_user_id'}
                    />
                  </Form.Label>
                  <Controller
                    control={control}
                    name="managing_user_id"
                    render={({ field }) => (
                      <ManagingUserSelectBox
                        isSearchable
                        className={errors.managing_user_id && 'is-invalid'}
                        name="managing_user_id"
                        onChange={user => field.onChange(user.id)}
                        value={field.value}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.managing_user_id &&
                      errors.managing_user_id.map(error => error)}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Check
                    {...register('managing_user_receives_monthly_report')}
                    isInvalid={errors.managing_user_receives_monthly_report}
                    label={
                      <>
                        Should contact person receive monthly report?
                        <LocalStorageTooltip
                          className="ms-1"
                          identifier={
                            'form_seo_project_field_contact person_receives_monthly_report'
                          }
                        />
                      </>
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.managing_user_receives_monthly_report &&
                      errors.managing_user_receives_monthly_report.map(
                        error => error
                      )}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Status
                    <LocalStorageTooltip
                      className="ms-1"
                      identifier={'form_seo_project_status'}
                    />
                  </Form.Label>
                  <Form.Control
                    {...register('presentable_status', {
                      required: true,
                      disabled: true
                    })}
                    isInvalid={errors.active}
                  >
                    {/* <option>Please select status</option>
                    <option value={'1'}>Active</option>
                    <option value={'0'}>In-Active</option> */}
                  </Form.Control>
                  <Form.Text>
                    If the project should be terminated, fill this{' '}
                    <TerminationForm seoProject={data?.data} refetch={refetch}>
                      <span className="cursor-pointer btn-link">form</span>
                    </TerminationForm>
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <h5 className="mt-1 mb-3">Competitor Domains</h5>

                <table className="table">
                  <thead className="bg-aida-table-header text-900 text-nowrap align-middle">
                    <tr>
                      <th>
                        Competitors
                        <LocalStorageTooltip
                          className="ms-1"
                          identifier={`form-seo-project-table-competitor-domains-column-competitors`}
                        />
                      </th>
                      <th>
                        Domain
                        <LocalStorageTooltip
                          className="ms-1"
                          identifier={`form-seo-project-table-competitor-domains-column-domain`}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data?.data?.competitors || {}).map((n, i) => (
                      <tr key={i}>
                        <td>
                          Competitor {i + 1}
                          <LocalStorageTooltip
                            className="ms-1"
                            identifier={`form_seo_project_field_competitor_domain`}
                          />
                        </td>
                        <td>
                          <Form.Control
                            size="sm"
                            {...register(`competitors[${n}]`)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <h5 className="mb-3 mt-5">Reports</h5>
                <AdvanceTableWrapper
                  columns={[
                    {
                      accessor: 'step_and_month',
                      Header: (
                        <>
                          Step - Month
                          <LocalStorageTooltip
                            identifier={'table-reports-column-step-and-month'}
                          />
                        </>
                      )
                    },
                    {
                      accessor: 'date_to_send_to_client',
                      Header: (
                        <>
                          Send date
                          <LocalStorageTooltip
                            identifier={'table-reports-column-date-to-send'}
                          />
                        </>
                      )
                    },
                    {
                      accessor: 'action_name',
                      Header: (
                        <>
                          Action
                          <LocalStorageTooltip
                            identifier={'table-reports-column-action'}
                          />
                        </>
                      ),
                      Cell: ({ cell }) => {
                        return (
                          <>
                            {cell.value}{' '}
                            {cell.row.original?.extra_important ? (
                              <FontAwesomeIcon
                                className="text-warning"
                                icon={faStar}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      }
                    },
                    {
                      accessor: 'pdf_file_link',
                      Header: (
                        <>
                          Report
                          <LocalStorageTooltip
                            identifier={'table-reports-column-pdf'}
                          />
                        </>
                      ),
                      Cell: ({ cell }) => {
                        if (cell.value) {
                          return (
                            <>
                              <a
                                href={cell.value}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <LocalStorageTooltip
                                  identifier={'pdf-report'}
                                  customIcon={<FontAwesomeIcon icon="file-pdf" />}
                                />
                              </a>
                              <SendMonthlyReportModal
                                seoProject={data?.data}
                                seoProjectAction={cell.row.original}
                              />
                            </>
                          )
                        }
                        else if (cell.row.original?.generated_report_id) {
                          console.log("Generated report ID", cell.row.original?.generated_report_id);
                          return (
                            <>
                              <a
                                href={"/reports/" + cell.row.original?.generated_report_id}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <LocalStorageTooltip
                                  identifier={'pdf-report'}
                                  customIcon={<FontAwesomeIcon icon="globe" />}
                                />
                              </a>
                              <SendMonthlyReportModal
                                seoProject={data?.data}
                                seoProjectAction={cell.row.original}
                              />
                            </>
                          )
                        }
                        return null;
                      }

                    }
                  ]}
                  data={data?.data?.seo_project_actions || []}
                  sortable
                  pagination
                >
                  <AdvanceTable
                    table
                    headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      bordered: true,
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                    apiCallInProcess={isFetching}
                  />
                </AdvanceTableWrapper>
              </Col>
            </Row>
            <h5 className="mb-3">Keywords & URLs</h5>
            <AdvanceTableWrapper
              columns={[
                {
                  id: 'id',
                  Header: '',
                  Cell: ({ cell }) => (
                    <EditKeywordUrl
                      keyword={cell.row.original}
                      refetch={refetch}
                    />
                  )
                },
                {
                  accessor: 'keyword',
                  Header: (
                    <>
                      Keyword
                      <LocalStorageTooltip
                        identifier={'table-keywords-and-url-column-keyword'}
                      />
                    </>
                  )
                },
                {
                  accessor: 'url',
                  Header: (
                    <>
                      Url
                      <LocalStorageTooltip
                        identifier={'table-keywords-and-url-column-url'}
                      />
                    </>
                  ),
                  Cell: ({ cell }) =>
                    cell.value ? (
                      <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        <a href={cell.value} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon="external-link-alt" />{' '}
                          {cell.value}
                        </a>
                      </div>
                    ) : null
                },

                {
                  accessor: 'page_pdf_file_link',
                  Header: (
                    <>
                      Create Page PDF
                      <LocalStorageTooltip
                        identifier={'table-reports-column-create-page-pdf'}
                      />
                    </>
                  ),
                  headerProps: { className: 'text-center' },
                  cellProps: { className: 'text-center' },
                  Cell: ({ cell }) =>
                    cell.row.original?.create_page ? (
                      <>
                        {cell.value ? (
                          <a href={cell.value} target="_blank" rel="noreferrer">
                            <LocalStorageTooltip
                              identifier={'create-page-pdf-report'}
                              customIcon={<FontAwesomeIcon icon="file-pdf" />}
                            />
                          </a>
                        ) : (
                          <FontAwesomeIcon icon={'spinner'} spin />
                        )}
                      </>
                    ) : (
                      '-'
                    )
                },
                {
                  accessor: 'check_status_200',
                  Header: (
                    <>
                      Status-200
                      <LocalStorageTooltip
                        identifier={'table-keywords-and-url-column-status-code'}
                      />
                    </>
                  ),
                  headerProps: { className: 'text-center' },
                  cellProps: { className: 'text-center' },
                  Cell: ({ cell }) =>
                    isNull(cell.value) ? (
                      '-'
                    ) : (
                      <FontAwesomeIcon
                        className={cell.value ? 'text-success' : 'text-danger'}
                        icon={cell.value ? 'check' : 'times'}
                        size="lg"
                      />
                    )
                },
                {
                  accessor: 'check_working_ssl',
                  Header: (
                    <>
                      SSL
                      <LocalStorageTooltip
                        identifier={'table-keywords-and-url-column-ssl'}
                      />
                    </>
                  ),
                  headerProps: { className: 'text-center' },
                  cellProps: { className: 'text-center' },
                  Cell: ({ cell }) =>
                    isNull(cell.value) ? (
                      '-'
                    ) : (
                      <FontAwesomeIcon
                        className={cell.value ? 'text-success' : 'text-danger'}
                        icon={cell.value ? 'check' : 'times'}
                        size="lg"
                      />
                    )
                },
                {
                  accessor: 'check_working_indexable',
                  Header: (
                    <>
                      Indexable
                      <LocalStorageTooltip
                        identifier={'table-keywords-and-url-column-indexable'}
                      />
                    </>
                  ),
                  headerProps: { className: 'text-center' },
                  cellProps: { className: 'text-center' },
                  Cell: ({ cell }) =>
                    isNull(cell.value) ? (
                      '-'
                    ) : (
                      <FontAwesomeIcon
                        className={cell.value ? 'text-success' : 'text-danger'}
                        icon={cell.value ? 'check' : 'times'}
                        size="lg"
                      />
                    )
                }
              ]}
              data={data?.data?.seo_project_keywords || []}
              sortable
              pagination
            >
              <AdvanceTable
                table
                headerClassName="bg-aida-table-header text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
                apiCallInProcess={isFetching}
              />
            </AdvanceTableWrapper>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={() => setShow(false)}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            form="seo-project-form"
            variant="outline-primary"
            className="rounded-pill"
            type="submit"
            icon="save"
            size="sm"
          >
            {isEditing ? 'Update' : 'Save'}
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

UpsertModal.propTypes = {};

export default UpsertModal;
