import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
import { AidaAppContext } from 'context/AidaAppContext';

const Login = () => {
  const { user_role } = useContext(AidaAppContext);
  return (
    <>
      {(user_role == 'Sudo Admin' || user_role == "System Admin") ? null : (
        <Flex justifyContent="between" alignItems="center" className="mb-2">
          <h5>Log in</h5>
        </Flex>
      )}
      {/* <p className="fs--1 text-600 mb-0">
        or <Link to="/app/register">Create an account</Link>
      </p> */}
      <LoginForm />
    </>
  );
};

export default Login;
